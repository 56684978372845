// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-design-index-jsx": () => import("./../../../src/pages/design/index.jsx" /* webpackChunkName: "component---src-pages-design-index-jsx" */),
  "component---src-pages-design-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/design/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-design-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-else-js": () => import("./../../../src/pages/else.js" /* webpackChunkName: "component---src-pages-else-js" */),
  "component---src-pages-illustration-js": () => import("./../../../src/pages/illustration.js" /* webpackChunkName: "component---src-pages-illustration-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

